/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { ProjectRouteParams } from "areas/projects/components/ProjectLayout/ProjectLayout";
import { RouteComponentProps } from "react-router";
import PaperLayout from "components/PaperLayout/PaperLayout";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import { ScopeValues, VariableSetResource } from "client/resources/variableSetResource";
import { repository } from "clientInstance";
import { LibraryVariableSetResource, VariableSetContentType } from "client/resources/libraryVariableSetResource";
const styles = require("./style.less");
import { VariableResource } from "client/resources/variableResource";
import { ValueWithSource } from "areas/variables/VariableDisplayer/VariableDisplayer";
import { FilterableVariableDisplayer } from "areas/variables/VariableDisplayer/FilterableVariableDisplayer";
import mergeScopeValues from "areas/variables/MergeScopeValues";
import VariableSetSectionHeading from "areas/variables/VariableSetSectionHeading/VariableDisplayerSectionHeading";
import ExpansionButtons from "components/form/Sections/ExpansionButtons";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import VariableSetSelector from "areas/variables/VariableSetSelector/VariableSetSelector";
import { ActionButtonType } from "components/Button/ActionButton";
import ProjectResource from "client/resources/projectResource";
import { ValueSource } from "areas/variables/SourceLink/SourceLink";
import { Permission } from "client/resources";
import { difference, compact } from "lodash";
import { GroupedExpandableProps, default as GroupedExpandable } from "components/GroupedExpandable";
import { AdditionalFilter, VariableWithSource } from "areas/variables/VariableDisplayer";
import { convertVariableResourcesToVariablesWithSource } from "../../../../variables/convertVariableResourcesToVariablesWithSource";

type Loadable<T> = T | "notloaded";
function isLoaded<T>(loadable: Loadable<T>): loadable is T {
    return loadable !== "notloaded";
}

type VariablesAndScopes = { variables: ReadonlyArray<VariableWithSource>; scopeValues: ScopeValues };
const libraryVariableSetsContainerKey = "library_variable_sets";

interface LibraryVariableSetWithVariables {
    libraryVariableSet: LibraryVariableSetResource;
    variableAndScopes: Loadable<VariablesAndScopes>;
}

type Busies = { [key: string]: Promise<void> };

interface LibraryVariableSetsState extends OptionalFormBaseComponentState<ReadonlyArray<LibraryVariableSetWithVariables>> {
    busies: Busies;
    variableSetNameFilter: string;
    filteredModel: ReadonlyArray<LibraryVariableSetWithVariables>;
}

const sectionHeaderRowHeight = 48;

// Be careful when you change anything related to expanders here. They are implemented the way they are to make sure we
// can expand/collapse sections that user virtual scrolling.  A good test case is to have a library set with
// enough variables so the take more than 1 screen.
class LibraryVariableSetsInternal extends FormBaseComponent<RouteComponentProps<ProjectRouteParams> & GroupedExpandableProps, LibraryVariableSetsState, ReadonlyArray<LibraryVariableSetWithVariables>> {
    constructor(props: RouteComponentProps<ProjectRouteParams> & GroupedExpandableProps) {
        super(props);
        this.state = {
            busies: {},
            variableSetNameFilter: "",
            filteredModel: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.loadData());
    }

    componentWillReceiveProps(nextProps: GroupedExpandableProps) {
        if (nextProps === this.props) {
            return;
        }
        const changed = Object.keys(nextProps.expanders).filter(key => nextProps.expanders[key] && !this.props.expanders[key]);

        if (changed.length === 0) {
            return;
        }

        const promise = this.loadVariableSet(changed);
        const promises: Busies = changed.reduce((acc: Busies, key) => {
            acc[key] = promise;
            return acc;
        }, {});

        this.setState(state => ({
            busies: {
                ...state!.busies,
                ...promises,
            },
        }));
    }

    render() {
        const additionalFilter: AdditionalFilter = {
            value: this.state.variableSetNameFilter,
            onValueChanged: this.handleFilterChanged,
            fieldName: "variable set name",
        };

        return (
            <PaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                fullWidth={true}
                title={"Library Variable Sets"}
                sectionControl={
                    this.state.model && (
                        <OpenDialogButton
                            type={ActionButtonType.Primary}
                            label="Include Library Variable sets"
                            permission={{
                                permission: Permission.ProjectEdit,
                                wildcard: true,
                            }}
                        >
                            <VariableSetSelector selectedVariableSetIds={this.state.model ? this.state.model.map(m => m.libraryVariableSet.Id) : []} saveVariableSetsSelection={async variableSetIds => this.save(variableSetIds)} />
                        </OpenDialogButton>
                    )
                }
            >
                <ExpansionButtons expandAllOnMount={true} containerKey={libraryVariableSetsContainerKey} />
                <FilterableVariableDisplayer
                    availableScopes={this.getAvailableScopes()}
                    isProjectScoped={false}
                    variableSections={this.getVariables()}
                    doBusyTask={this.doBusyTask}
                    alwaysShowCheckboxFilters={true}
                    shouldHideSectionContent={sectionIndex => {
                        const variableSet = this.state.filteredModel[sectionIndex].libraryVariableSet;
                        return !this.props.expanders[variableSet.Id];
                    }}
                    additionalFilter={additionalFilter}
                    sectionHeader={{
                        sectionHeaderRowHeight,
                        renderSectionHeader: (sectionIndex, cellAligner) => {
                            const variableSet = this.state.filteredModel[sectionIndex].libraryVariableSet;
                            return (
                                <div className={styles.sectionHeader}>
                                    <VariableSetSectionHeading
                                        key={variableSet.Id}
                                        variableSetId={variableSet.Id}
                                        variableSetName={variableSet.Name}
                                        variableSetTab="variables"
                                        isExpanded={this.props.expanders[variableSet.Id]!}
                                        busy={this.state.busies[variableSet.Id]}
                                        onExpandedChanged={expanded => this.props.onExpandedChanged(variableSet.Id, expanded)}
                                        onRemoveVariableSet={() => this.removeVariableSet(variableSet.Id)}
                                    />
                                </div>
                            );
                        },
                    }}
                />
            </PaperLayout>
        );
    }

    private handleFilterChanged = (val: string) => {
        this.setState(state => ({
            variableSetNameFilter: val,
            filteredModel: this.filterByName(state!.model!, val),
        }));
    };

    private async removeVariableSet(libraryVariableSetId: string) {
        const selectedVariableSetIds = this.state.model!.map(m => m.libraryVariableSet.Id).filter(id => id !== libraryVariableSetId);
        await this.save(selectedVariableSetIds);

        return Promise.resolve(true);
    }

    private async loadVariableSet(libraryVariableSetIds: string[]) {
        const toLoad = compact(
            libraryVariableSetIds.map(id => {
                const current = this.state.model!.find(m => m.libraryVariableSet.Id === id);
                return isLoaded(current!.variableAndScopes) ? null : current!.libraryVariableSet.VariableSetId;
            })
        );

        const variableSets = await repository.Variables.all({ ids: toLoad! });

        this.setState(prevState => {
            const next = prevState!.model!.map(current => {
                const variableSet = variableSets.find((vs: VariableSetResource) => vs.Id === current.libraryVariableSet.VariableSetId);
                if (variableSet) {
                    return {
                        libraryVariableSet: current.libraryVariableSet,
                        variableAndScopes: {
                            variables: convertVariableResourcesToVariablesWithSource(variableSet.Variables, {
                                variableSetName: current.libraryVariableSet.Name,
                                variableSetId: current.libraryVariableSet.Id,
                            }),
                            scopeValues: variableSet.ScopeValues,
                        },
                    };
                } else {
                    return current;
                }
            });
            return {
                model: next,
                filteredModel: this.filterByName(next, this.state.variableSetNameFilter),
            };
        });
    }

    private filterByName(list: ReadonlyArray<LibraryVariableSetWithVariables>, filter: string): ReadonlyArray<LibraryVariableSetWithVariables> {
        return list.filter(set => !filter || set.libraryVariableSet.Name.includes(filter));
    }

    private getAvailableScopes(): ScopeValues {
        const allScopeValues: ScopeValues[] = this.state.model ? this.state.model.filter(set => isLoaded(set.variableAndScopes)).map(set => (set.variableAndScopes as VariablesAndScopes).scopeValues) : [];

        return mergeScopeValues(allScopeValues);
    }

    private async loadData() {
        const project = await repository.Projects.get(this.props.match.params.projectSlug);
        const libraryVariableSets = await repository.LibraryVariableSets.all({ ids: project.IncludedLibraryVariableSetIds, contentType: VariableSetContentType.Variables });
        const next = libraryVariableSets.map(libraryVariableSet => ({
            libraryVariableSet,
            variableAndScopes: "notloaded" as Loadable<VariablesAndScopes>,
        }));

        this.setState(
            {
                model: next,
                filteredModel: this.filterByName(next, this.state.variableSetNameFilter),
            },
            () => this.props.registerAllExpanders(libraryVariableSets.map(v => v.Id))
        );
    }

    private async save(selectedVariableSetIds: ReadonlyArray<string>) {
        // Should we be reloading the project here? or just using whatever was loaded previously
        const [project, allvariablesSet] = await Promise.all([repository.Projects.get(this.props.match.params.projectSlug), repository.LibraryVariableSets.all({ contentType: VariableSetContentType.Variables })]);

        const extraSets = difference(
            project.IncludedLibraryVariableSetIds,
            allvariablesSet.map(v => v.Id)
        );

        const updatedProject: ProjectResource = {
            ...project,
            IncludedLibraryVariableSetIds: [...selectedVariableSetIds, ...extraSets],
        };
        await repository.Projects.modify(updatedProject);
        await this.loadData();
    }

    private getVariables() {
        const variableSets = this.state.model ? this.state.filteredModel.map(set => (isLoaded(set.variableAndScopes) ? [...set.variableAndScopes.variables] : [])) : [];

        return variableSets;
    }
}

function createVariable(variableResource: VariableResource, set: ValueSource): ValueWithSource {
    return {
        value: variableResource.Value!,
        type: variableResource.Type,
        description: variableResource.Description,
        scope: variableResource.Scope,
        source: set,
        isPrompted: !!variableResource.Prompt,
    };
}

const LibraryVariableSets = GroupedExpandable(libraryVariableSetsContainerKey, LibraryVariableSetsInternal);
export default LibraryVariableSets;

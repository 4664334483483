/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import NavigationButton, { NavigationButtonType } from "components/Button/NavigationButton";
import { PermissionCheck } from "components/PermissionCheck";
import { Permission, RunbookSnapshotResource, RunbookResource } from "client/resources";
import routeLinks from "routeLinks";
import ToolTip from "components/ToolTip";
import { useRunbookContext } from "./RunbookContext";
import { useProjectContext } from "areas/projects/context/ProjectContext";
import { DoBusyTask, useDoBusyTaskEffect } from "components/DataBaseComponent";
import LastPublishedChip from "./LastPublishedChip";
import { repository } from "clientInstance";
import StringHelper from "utils/StringHelper";
import { isRunbookConsumerOnly } from "./RunbookOverviewLayout";
import { useProcessContext, useOptionalProcessContext } from "areas/projects/components/Process/Contexts/ProcessContext";
const styles = require("./PublishButton.less");

export const publishingExplainedElement = <span>Publishing makes the runbook available to consumers and triggers.</span>;

interface PublishedRunbookSnapshotState {
    publishedRunbookSnapshot: RunbookSnapshotResource;
    isRunbookRunTemplateModified: boolean;
    lookupComplete: boolean;
    version: number | null;
}

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<PublishedRunbookSnapshotState>>) => {
    return {
        onPublishedRunbookSnapshotUpdated: (publishedRunbookSnapshot: RunbookSnapshotResource, isRunbookRunTemplateModified: boolean, lookupComplete: boolean, version: number | null) =>
            setState(current => ({ ...current, publishedRunbookSnapshot, isRunbookRunTemplateModified, lookupComplete, version })),
    };
};

const usePublishedRunbookSnapshotSetup = (doBusyTask: DoBusyTask, runbook: RunbookResource, processVersion: number | null) => {
    const [state, setState] = React.useState<PublishedRunbookSnapshotState>({
        publishedRunbookSnapshot: null!,
        isRunbookRunTemplateModified: false,
        lookupComplete: false,
        version: null,
    });

    const updaters = getStateUpdaters(setState);

    const refreshPublishedRunbookSnapshot = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            let publishedRunbookSnapshot: RunbookSnapshotResource;
            let isRunbookRunTemplateModified = false;
            if (runbook.PublishedRunbookSnapshotId) {
                publishedRunbookSnapshot = await repository.RunbookSnapshots.get(runbook.PublishedRunbookSnapshotId);
                const runbookRunTemplate = await repository.RunbookSnapshots.getRunbookRunTemplate(publishedRunbookSnapshot);
                isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
            }
            updaters.onPublishedRunbookSnapshotUpdated(publishedRunbookSnapshot!, isRunbookRunTemplateModified, true, processVersion);
        },
        [runbook.PublishedRunbookSnapshotId, processVersion]
    );

    return {
        actions: { ...updaters, refreshActionTemplates: refreshPublishedRunbookSnapshot },
        state,
        setState,
    };
};

export interface PublishButtonProps {
    doBusyTask: DoBusyTask;
    isDisabled?: boolean;
}

export const PublishButton: React.FC<PublishButtonProps> = ({ doBusyTask, isDisabled }) => {
    const projectContext = useProjectContext();
    const runbookContext = useRunbookContext();
    const project = projectContext.state.model;
    const runbook = runbookContext.state.runbook;

    const processContext = useOptionalProcessContext();
    const process = processContext?.state.model.process;

    // If a published version exists, load it and determine if there's been any changes since it was published.
    const value = usePublishedRunbookSnapshotSetup(doBusyTask, runbook!, process ? process.Version : null);

    // We need to know when the process updates.
    if (!processContext) {
        return null;
    }

    if (!value.state.lookupComplete) {
        return <div>{StringHelper.ellipsis}</div>;
    }
    const publishedRunbookSnapshot = value.state.publishedRunbookSnapshot;
    const isRunbookRunTemplateModified = value.state.isRunbookRunTemplateModified;
    const showChangeSnapshotWarnings = !isRunbookConsumerOnly(project.Id);
    const showOutOfDate = isRunbookRunTemplateModified && showChangeSnapshotWarnings;

    const lastPublishedChip = <LastPublishedChip projectSlug={project.Slug} publishedRunbookSnapshot={publishedRunbookSnapshot} isOutOfDate={showOutOfDate} />;
    const publishButton = (
        <PermissionCheck permission={Permission.RunbookEdit} project={project.Id} wildcard={true}>
            <div className={styles.container}>
                <ToolTip content={publishingExplainedElement}>
                    <NavigationButton label={"Publish..."} href={routeLinks.project(project.Slug).operations.runbook(runbook!.Id).runbookSnapshotCreate} type={NavigationButtonType.Ternary} disabled={isDisabled} />
                </ToolTip>
            </div>
        </PermissionCheck>
    );

    //TODO: OPS
    // - Consider moving most of this setup bits into a hook / function so we can test this independently from the component.
    // - See https://github.com/OctopusDeploy/OctopusDeploy/pull/4915#pullrequestreview-328664248

    if (publishedRunbookSnapshot && !isRunbookRunTemplateModified) {
        return lastPublishedChip;
    }
    if (publishedRunbookSnapshot && isRunbookRunTemplateModified) {
        return (
            <>
                {lastPublishedChip}
                {publishButton}
            </>
        );
    }
    return publishButton;
};

export default PublishButton;

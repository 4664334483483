import Logo from "components/Logo";
import ActionTemplateEditor from "components/ActionTemplateEditor/ActionTemplateEditor";
import * as React from "react";
import { DeploymentActionResource, HasManualInterventionResponsibleTeams, TenantedDeploymentMode, ActionTemplateResource, RunConditionForAction, ProcessType } from "client/resources";
import { ActionScope, AdditionalActions } from "components/Actions/pluginRegistry";
import { isEqual, uniq } from "lodash";
import { repository } from "clientInstance";
import { required, Note, UnstructuredFormSection } from "components/form";
import ActionEditor from "components/ActionEditor/ActionEditor";
import FeatureEditor from "components/FeatureEditor/FeatureEditor";
import { ExpandableFormSection, FormSectionHeading, Summary } from "components/form";
import { Callout, CalloutType } from "components/Callout";
import { ChannelMultiSelect } from "components/MultiSelect";
import { Feature } from "components/FeatureToggle";
import FeatureToggle from "components/FeatureToggle/FeatureToggle";
import Checkbox from "components/form/Checkbox/Checkbox";
import { ChannelChip, MissingChip, ChipIcon } from "components/Chips";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import StartTriggerExpander from "../Process/Common/StartTriggerExpander";
import RunTriggerExpander from "../Process/Common/RunTriggerExpander";
import PackageRequirementExpander from "../Process/Common/PackageRequirementExpander";
import OpenFeatureDialog from "components/OpenFeatureDialog/OpenFeatureDialog";
import { connect } from "react-redux";
import Environments from "../Process/Common/Environments";
import ExecutionPlan from "../Process/Common/ExecutionPlan";
import TenantsExpander from "../Process/Common/TenantsExpander";
import ActionProperties from "client/resources/actionProperties";
import getActionLogoUrl from "../getActionLogoUrl";
import { PackageRequirement } from "client/resources";
import { PackageReference } from "client/resources/packageReference";
import StepName from "../Process/Common/StepName";
import { OverflowMenuItems, OverflowMenu } from "components/Menu";
import { withProjectContext, WithProjectContextInjectedProps } from "../../context";
import { MenuItem } from "components/Menu/OverflowMenu";
import FeedResource from "client/resources/feedResource";
import RunTriggerForChildActionExpander from "../Process/Common/RunTriggerForChildActionExpander";
import { withOptionalRunbookContext, WithOptionalRunbookContextInjectedProps } from "../Runbooks/RunbookContext";
import { memoize } from "lodash";
import { ScriptActionContext } from "components/Actions/script/ScriptActionContext";
import { Errors } from "components/DataBaseComponent/Errors";
import { whereToRun, runsOnServer, processScopedEditPermission, deleteActionAndRedirect, generateDefaultActionContainer, isRunOnServerOrWorkerPool } from "./Common/CommonProcessHelpers";
import { ProjectRouteParams } from "../ProjectLayout/ProjectLayout";
import { generateGuid } from "./generation";
import { ProcessStepActionData } from "./ProcessStepsLayout";
import PaperLayout from "components/PaperLayout";
import ActionList from "components/ActionList";
import { DoBusyTask } from "components/DataBaseComponent";
import BaseComponent from "components/BaseComponent";
import { WithProcessContextInjectedProps, withProcessContext, useProcessContext } from "./Contexts/ProcessContext";
import { StoredStep, StoredAction, RunOnServerOrWorkerPool, EnvironmentOption, RunOn, EnvironmentSelection, RunOnDeploymentTarget, ExecutionLocation } from "./types";
import { ConflictConfirmationDialog } from "./Common/ConflictConfirmationDialog";
import { ErrorsForAction } from "./Common/ErrorsForAction";
import { withProcessErrorSelectorsContext, WithProcessErrorSelectorContextInjectedProps, useProcessErrorSelectors } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { DebounceText } from "components/form/Text/Text";
import { WithProcessQueryStringContextInjectedProps, withProcessQueryStringContext } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import { ExpandableContainer } from "components/Expandable";
import ExpandErrors from "components/Expandable/ExpandErrors";
import ExpandAllExpanders from "components/Expandable/ExpandAllExpanders";
import { selectors } from "components/Dialog/store";
import { isFirstStep } from "./Contexts/processModelSelectors";

//TODO: @Cleanup - We want to remove the state from this component if possible. Functional components would make this easier to reason about.
interface ProcessActionDetailsState {
    canRunBeforeAcquisition: boolean;
    runOnServerOrWorkerPoolCopy: RunOnServerOrWorkerPool | null;
    runOn: RunOn;
    environmentOption: EnvironmentOption;
}

export type ProcessActionDetailsProps = {
    processType: ProcessType;
    isNew: boolean;
    actionType?: string;
    parentStepId?: string;
    reloadKey?: string;
    errors: Errors | undefined;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    step: StoredStep;
    action: StoredAction;
    refreshStepLookups: () => void;
} & ProcessStepActionData;

interface GlobalConnectedProps {
    isBuiltInWorkerEnabled: boolean;
}

type Props = ProcessActionDetailsProps & WithProjectContextInjectedProps & WithProcessContextInjectedProps & WithOptionalRunbookContextInjectedProps & WithProcessQueryStringContextInjectedProps & WithProcessErrorSelectorContextInjectedProps;

const AutoExpandActionErrors: React.FC<{ actionId: string }> = props => {
    const selectors = useProcessErrorSelectors();
    const processContext = useProcessContext();

    const getErrors = React.useCallback(() => Object.keys(selectors.getActionFieldErrors(props.actionId, processContext.selectors)), [selectors, props.actionId, processContext.selectors]);

    return <ExpandErrors getErrors={getErrors} />;
};

class ProcessActionDetailsInternal extends BaseComponent<Props, ProcessActionDetailsState> {
    private get isNew(): boolean {
        return this.props.isNew;
    }
    private memoizeLoadVariables = memoize(() => repository.Variables.names(this.props.projectContext.state.model.Id, this.props.runbookContext?.state.runbook?.Id));

    constructor(props: Props) {
        super(props);
        this.state = this.getStateUpdate();
    }

    getStateUpdate(): ProcessActionDetailsState {
        const props = this.props;
        const actionId = props.action.Id;
        const runOn = props.stepOther.runOn ?? new RunOnDeploymentTarget();
        const action = props.action;

        return {
            canRunBeforeAcquisition: props.processContext.selectors.actionCanRunBeforeAcquisition(actionId),
            runOnServerOrWorkerPoolCopy: isRunOnServerOrWorkerPool(runOn) ? runOn : null,
            runOn,
            environmentOption: (action.Environments || []).length > 0 ? EnvironmentOption.Include : (action.ExcludedEnvironments || []).length > 0 ? EnvironmentOption.Exclude : EnvironmentOption.All,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (!isEqual(prevProps.stepOther, this.props.stepOther) || prevProps.action.Id !== this.props.action.Id) {
            this.setState(this.getStateUpdate());
        }
    }

    refreshRunOn() {
        const runOn = whereToRun(!!this.props.step.Properties["Octopus.Action.TargetRoles"], this.props.action, this.props.stepLookups.availableWorkerPools, this.props.stepOther.plugin, this.props.stepOther.isBuiltInWorkerEnabled);
        this.setState({ runOn }, () => {
            this.setActionProperties({ ["Octopus.Action.RunOnServer"]: runOn.executionLocation === ExecutionLocation.DeploymentTarget ? "false" : "true" });
        });
    }

    isChildAction = () => {
        return this.props.processContext.selectors.isChildAction(this.props.action.Id);
    };

    doBusyForChildren = (action: () => Promise<void>): Promise<boolean> => {
        // don't clear errors on child tasks since they should just
        // be loading and we don't want to clear a Save error
        // just because we load some lookup data
        return this.props.doBusyTask(action, false);
    };

    loadVariables = () => {
        return this.memoizeLoadVariables();
    };

    render() {
        const { step, action, processType, stepLookups, stepOther, projectContext, processContext, processQueryStringContext, busy, errors } = this.props;
        const { actions: contextActions, selectors: contextSelectors } = processContext;
        const { actions: queryStringActions } = processQueryStringContext;
        const scope = processContext.selectors.getActionScope();

        const addFeaturesElement =
            processContext.selectors.actionHasFeatures(action.Id) && !stepLookups.actionTemplate ? (
                <OpenFeatureDialog scope={scope} actionType={action.ActionType} properties={action.Properties} saveDone={x => this.setActionProperties({ ["Octopus.Action.EnabledFeatures"]: x })} />
            ) : (
                undefined
            );

        const actionEditorAdditionalActions: AdditionalActions = {
            packageAcquisition: {
                canRunBeforeAcquisition: this.state.canRunBeforeAcquisition,
                stepPackageRequirement: step.PackageRequirement,
                onCanRunBeforeAcquisitionChanged: (x: boolean) => {
                    this.setState({ canRunBeforeAcquisition: x });
                },
                onStepPackageRequirementChanged: (x: PackageRequirement) => {
                    this.setStepMetaProperties({ PackageRequirement: x });
                },
            },
            stepTargetRoles: step.Properties["Octopus.Action.TargetRoles"] as string,
            actionType: this.props.stepOther.plugin.actionType,
        };

        const hasManualInterventionResponsibleTeams = action && HasManualInterventionResponsibleTeams(action);

        const processEditPermission = { permission: processScopedEditPermission(processType), project: projectContext.state.model.Id, wildcard: true };
        const menuActions: Array<MenuItem | MenuItem[]> = [];
        if (step && step.Id) {
            // doesn't make sense to allow enable/disable/delete if the step hasn't been saved
            // it will also cause havoc - eg delete will delete *another* step.
            if (action) {
                menuActions.push(OverflowMenuItems.item(action.IsDisabled ? "Enable" : "Disable", () => this.handleEnabledToggle(), processEditPermission));
            }
            if (action) {
                menuActions.push(OverflowMenuItems.deleteItemDefault("step", () => deleteActionAndRedirect(step, action, true, contextActions, contextSelectors, queryStringActions), processEditPermission));
            } else {
                menuActions.push(OverflowMenuItems.deleteItemDefault("parent step", () => deleteActionAndRedirect(step, action, true, contextActions, contextSelectors, queryStringActions), processEditPermission));
            }

            if (stepLookups.actionTemplate) {
                menuActions.push(OverflowMenuItems.item("Detach Step Template", async () => this.handleDetachStepTemplate()));
            } else {
                menuActions.push(OverflowMenuItems.item("Extract Step Template", async () => this.handleCreateStepTemplate()));
            }
        }

        const actions = [];
        actions.push(addFeaturesElement);
        actions.push(<OverflowMenu menuItems={menuActions} />);

        const isChildAction = contextSelectors.isChildAction(action.Id);
        const stepActionNumber = isChildAction ? `${contextSelectors.getStepNumber(step.Id)}.${contextSelectors.getActionNumber(action.Id)}` : `${contextSelectors.getStepNumber(step.Id)}`;
        const actionErrors = action ? this.props.processErrorSelectors.getActionErrors(action.Id, processContext.selectors) : [];

        return (
            <PaperLayout
                title={<StepName name={action.Name} number={stepActionNumber} stepType={stepOther.actionTypeName} />}
                titleLogo={action && <Logo url={getActionLogoUrl(action)} />}
                busy={undefined} // Handled by parent.
                errors={undefined} // Handled by parent.
                sectionControl={<ActionList actions={actions} />}
                disableHeaderAnimations={true} // Disabling due to the way the ProcessStepDetailsLoader and this component work together.
                fullWidth={true}
                flatStyle={true}
                hideHelpIcon={true}
                disableScrollToActiveError={true} // We have custom error handling for our process form.
                disableStickyHeader={true} // We have a custom layout above this which is already sticky.
            >
                <ExpandableContainer containerKey={action.Id}>
                    <AutoExpandActionErrors actionId={action.Id} />
                    <ErrorsForAction actionErrors={actionErrors} />
                    {actionErrors.length === 0 && this.props.isNew && <ExpandAllExpanders />}
                    <ScriptActionContext.Provider value={{ loadVariables: this.loadVariables }}>
                        <div>
                            <ConflictConfirmationDialog />

                            {action.IsDisabled && (
                                <UnstructuredFormSection stretchContent={true}>
                                    <Callout type={CalloutType.Warning} title={"This step is currently disabled"} />
                                </UnstructuredFormSection>
                            )}

                            <ExpandableFormSection
                                isExpandedByDefault={this.props.isNew}
                                errorKey="Name"
                                title="Step Name"
                                focusOnExpandAll
                                summary={action.Name ? Summary.summary(action.Name) : Summary.placeholder("Please enter a name for your step")}
                                help="A short, memorable, unique name for this step."
                            >
                                <DebounceText value={action.Name} onChange={x => this.setActionMetaProperties({ Name: x })} label="Step name" error={this.getFieldError("Name")} validate={required("Please enter a step name")} autoFocus={true} />
                            </ExpandableFormSection>

                            <ExpandableFormSection errorKey="IsDisabled" title="Enabled" summary={action.IsDisabled ? Summary.summary("No") : Summary.default("Yes")} help="Disable a step to prevent it from running.">
                                <Checkbox value={!action.IsDisabled} onChange={IsDisabled => this.setActionMetaProperties({ IsDisabled: !IsDisabled })} label="Enabled" />
                            </ExpandableFormSection>

                            <ExecutionPlan
                                projectId={projectContext.state.model.Id}
                                expandedByDefault={this.props.isNew}
                                doBusyTask={this.doBusyForChildren}
                                executionLocation={this.props.stepOther.plugin.executionLocation}
                                runOnServerOrWorkerPoolCopy={this.state.runOnServerOrWorkerPoolCopy}
                                runOn={this.state.runOn}
                                onRunOnChanged={this.onRunOnChanged}
                                targetRoleOption={this.props.stepOther.plugin.targetRoleOption(action)}
                                targetRoles={step.Properties["Octopus.Action.TargetRoles"] as string}
                                disableAddTargetRoles={this.props.stepOther.plugin.disableAddTargetRoles}
                                onTargetRolesChanged={roles => this.setStepProperties({ ["Octopus.Action.TargetRoles"]: ParseHelper.encodeCSV(roles) })}
                                targetRolesError={this.getFieldError("Octopus.Action.TargetRoles")}
                                isChildStep={contextSelectors.isChildAction(action.Id)}
                                maxParallelism={step.Properties["Octopus.Action.MaxParallelism"] as string}
                                onMaxParallelismChanged={max => this.setStepProperties({ ["Octopus.Action.MaxParallelism"]: max })}
                                availableRoles={stepLookups.machineRoles}
                                availableWorkerPools={stepLookups.availableWorkerPools}
                                canRunOnWorker={contextSelectors.canActionRunOnWorker(action.Id)}
                                isBuiltInWorkerEnabled={stepOther.isBuiltInWorkerEnabled}
                                targetWorkerPool={action.WorkerPoolId}
                                targetWorkerPoolVariable={action.WorkerPoolVariable}
                                onTargetWorkerPoolChanged={(workerPoolId, workerPoolVariable) => this.setActionMetaProperties({ WorkerPoolId: workerPoolId, WorkerPoolVariable: workerPoolVariable })}
                                runsOnServer={runsOnServer(action, this.props.stepOther.plugin.executionLocation)}
                                getFieldError={this.getFieldError}
                                feeds={processContext.lookupsState.feeds}
                                loadFeeds={this.loadFeeds}
                                canRunInContainer={this.canRunInContainer()}
                                imageNameError={this.getFieldError("Container.FeedId")}
                            />

                            {!stepLookups.actionTemplate && (
                                <div>
                                    <ActionEditor
                                        scope={scope}
                                        plugin={this.props.stepOther.plugin}
                                        projectId={projectContext.state.model.Id}
                                        isNew={this.isNew}
                                        doBusyTask={this.doBusyForChildren}
                                        busy={busy}
                                        properties={action.Properties}
                                        packages={action.Packages}
                                        runOn={this.state.runOn}
                                        setProperties={(p, i, c) => this.setActionProperties(p, c)}
                                        setPackages={p => this.setActionPackages(p)}
                                        additionalActions={actionEditorAdditionalActions}
                                        getFieldError={this.getFieldError}
                                        errors={errors}
                                        expandedByDefault={this.props.isNew}
                                        refreshRunOn={() => this.refreshRunOn()}
                                    />

                                    {processContext.selectors.actionHasFeatures(action.Id) && (
                                        <FeatureEditor
                                            scope={scope}
                                            plugin={this.props.stepOther.plugin}
                                            projectId={projectContext.state.model.Id}
                                            isNew={this.isNew}
                                            doBusyTask={this.doBusyForChildren}
                                            busy={busy}
                                            properties={action.Properties}
                                            packages={action.Packages}
                                            runOn={this.state.runOn}
                                            setProperties={(p, i, c) => this.setActionProperties(p, c)}
                                            setPackages={p => this.setActionPackages(p)}
                                            enabledFeatures={(action.Properties["Octopus.Action.EnabledFeatures"] as string) || ""}
                                            getFieldError={this.getFieldError}
                                            errors={errors}
                                            expandedByDefault={this.props.isNew}
                                            openFeaturesElement={addFeaturesElement}
                                            refreshRunOn={() => this.refreshRunOn()}
                                        />
                                    )}
                                </div>
                            )}

                            {stepLookups.actionTemplate && (
                                <ActionTemplateEditor
                                    actionTemplate={stepLookups.actionTemplate}
                                    projectId={projectContext.state.model.Id}
                                    process={processContext.state.model}
                                    scope={scope}
                                    actionId={action.Id}
                                    properties={action.Properties}
                                    setProperties={p => this.setActionProperties(p)}
                                    setPackages={p => this.setActionPackages(p)}
                                    doBusyTask={this.doBusyForChildren}
                                />
                            )}

                            <FormSectionHeading title="Conditions" />
                            <Environments
                                environmentOption={this.state.environmentOption}
                                hasHiddenEnvironments={this.props.stepOther.environmentSelection.hasHiddenEnvironments}
                                environments={this.props.stepLookups.environments}
                                inclusiveEnvironments={action.Environments}
                                exclusiveEnvironments={action.ExcludedEnvironments}
                                onEnvironmentOptionChanged={environmentOption => this.updateEnvironmentOption(environmentOption)}
                                onInclusiveEnvironmentsChanged={val => this.updateEnvironmentSelection({ ...this.props.stepOther.environmentSelection, inclusive: val })}
                                onExclusiveEnvironmentsChanged={val => this.updateEnvironmentSelection({ ...this.props.stepOther.environmentSelection, exclusive: val })}
                            />

                            {scope === ActionScope.Deployments && (action.Channels.length > 0 || stepLookups.channels.length > 1) && (
                                <ExpandableFormSection title="Channels" help="Choose which channels this step applies to." summary={this.channelsSummary()} errorKey="channels">
                                    <Note>If nothing is selected this step will run for releases in any channel, otherwise it will only run for releases belonging to the selected channels.</Note>
                                    <ChannelMultiSelect items={stepLookups.channels} onChange={val => this.setActionMetaProperties({ Channels: val })} value={action.Channels} />
                                </ExpandableFormSection>
                            )}

                            <FeatureToggle feature={Feature.MultiTenancy}>
                                {(projectContext.state.model.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted || action.TenantTags.length > 0) && (
                                    <TenantsExpander doBusyTask={this.doBusyForChildren} tenantTags={action.TenantTags} tagIndex={stepLookups.tagIndex} onTenantTagsChange={tags => this.setActionMetaProperties({ TenantTags: tags })} />
                                )}
                            </FeatureToggle>

                            {!this.isChildAction() && step && (
                                <RunTriggerExpander
                                    isFirstStep={contextSelectors.isFirstStep(step.Id)}
                                    condition={step.Condition}
                                    onConditionChange={val => this.setStepMetaProperties({ Condition: val })}
                                    variableExpression={step.Properties["Octopus.Action.ConditionVariableExpression"] as string}
                                    onVariableExpressionChange={x => this.setStepProperties({ ["Octopus.Action.ConditionVariableExpression"]: x })}
                                    projectId={projectContext.state.model.Id}
                                    variableExpressionError={this.getFieldError("ConditionVariableExpression")}
                                />
                            )}
                            {this.isChildAction() && action && (
                                <RunTriggerForChildActionExpander
                                    isFirstStep={contextSelectors.isFirstChildAction(action.Id)}
                                    condition={action.Condition || RunConditionForAction.Success}
                                    onConditionChange={val => this.setActionMetaProperties({ Condition: val })}
                                    variableExpression={action.Properties["Octopus.Action.ConditionVariableExpression"] as string}
                                    onVariableExpressionChange={x => this.setActionProperties({ ["Octopus.Action.ConditionVariableExpression"]: x })}
                                    projectId={projectContext.state.model.Id}
                                    variableExpressionError={this.getFieldError("ConditionVariableExpression")}
                                />
                            )}

                            {contextSelectors.shouldShowRunTrigger(action.Id) && <StartTriggerExpander startTrigger={step.StartTrigger} onChange={val => this.setStepMetaProperties({ StartTrigger: val })} />}

                            {this.state.canRunBeforeAcquisition && step && <PackageRequirementExpander packageRequirement={step.PackageRequirement} onChange={val => this.setStepMetaProperties({ PackageRequirement: val })} />}

                            <ExpandableFormSection
                                title="Required"
                                summary={
                                    action.IsRequired || hasManualInterventionResponsibleTeams
                                        ? Summary.summary(
                                              <span>
                                                  This step is <strong>required</strong> and cannot be skipped
                                              </span>
                                          )
                                        : Summary.summary(
                                              <span>
                                                  This step is <strong>not required</strong> and can be skipped
                                              </span>
                                          )
                                }
                                help="Required steps cannot be skipped when deploying a release"
                                errorKey="required"
                            >
                                {hasManualInterventionResponsibleTeams && <Note>Responsible teams are specified, therefore this step is always required.</Note>}

                                <Checkbox
                                    value={action.IsRequired || hasManualInterventionResponsibleTeams}
                                    label="Prevent this step from being skipped when deploying"
                                    disabled={hasManualInterventionResponsibleTeams}
                                    onChange={val => this.setActionMetaProperties({ IsRequired: val })}
                                />
                            </ExpandableFormSection>
                        </div>
                    </ScriptActionContext.Provider>
                </ExpandableContainer>
            </PaperLayout>
        );
    }

    private updateEnvironmentOption(environmentOption: EnvironmentOption) {
        this.setState({ environmentOption }, () => {
            this.updateEnvironments(this.props.stepOther.environmentSelection, environmentOption);
        });
    }

    private updateEnvironmentSelection(environmentSelection: EnvironmentSelection) {
        this.updateEnvironments(environmentSelection, this.state.environmentOption);
    }

    private updateEnvironments(selection: EnvironmentSelection | null, environmentOption: EnvironmentOption | null) {
        const environments = selection ? uniq((selection.inclusive || []).concat(selection.unavailable)) : [];
        const excludedEnvironments = selection ? uniq((selection.exclusive || []).concat(selection.unavailableExclusive)) : [];

        if (environmentOption && environmentOption !== EnvironmentOption.Include) {
            environments.splice(0);
        }
        if (environmentOption && environmentOption !== EnvironmentOption.Exclude) {
            excludedEnvironments.splice(0);
        }

        this.setActionMetaProperties({ Environments: environments, ExcludedEnvironments: excludedEnvironments });
    }

    private getFieldError = (value: string) => {
        return this.props.processErrorSelectors.getActionFieldError(this.props.action.Id, this.props.processContext.selectors, value);
    };

    private onRunOnChanged = (runOn: RunOn) => {
        if (this.state.runOn && isRunOnServerOrWorkerPool(this.state.runOn)) {
            this.setState({ runOnServerOrWorkerPoolCopy: this.state.runOn });
        }

        this.setState({ runOn }, () => {
            this.props.processContext.actions.runOnChanged(this.props.action.Id, this.props.step.Id, runOn);
        });
    };

    private loadFeeds = async (callback?: (feeds: FeedResource[]) => void) => {
        await this.props.doBusyTask(async () => {
            await this.props.processContext.actions.refreshFeeds();
            if (callback) {
                callback(this.props.processContext.lookupsState.feeds);
            }
        });
    };

    private handleEnabledToggle = async () => {
        const action = this.props.action;
        this.setActionMetaProperties({ IsDisabled: !action.IsDisabled });
    };

    private refreshStepLookupsImmediately() {
        //TODO: This is not ideal. We need this to happen after a setState is called, and we don't have a side effect
        // in our reducers atm, so we can't just say `setState(..., () => nowDoThis)`, hence the setImmediate.
        setImmediate(() => this.props.refreshStepLookups());
    }

    private handleDetachStepTemplate = async () => {
        const action = this.props.action;
        this.props.processContext.actions.removeActionProperties(action.Id, ["Octopus.Action.Template.Id", "Octopus.Action.Template.Version"]);
        this.refreshStepLookupsImmediately();
    };

    private handleCreateStepTemplate = async () => {
        const templateExists = (templates: ActionTemplateResource[], actionName: string) => {
            return templates.some(s => s.Name.toLocaleUpperCase() === actionName.toLocaleUpperCase());
        };

        const getNewTemplateName = (templates: ActionTemplateResource[], action: DeploymentActionResource) => {
            let suffix = "";
            let counter = 1;
            while (templateExists(templates, action.Name + suffix)) {
                suffix = " (" + counter + ")";
                counter++;
            }
            return action.Name + suffix;
        };

        const createStepTemplateFromAction = async (action: DeploymentActionResource) => {
            const existingTemplates = await repository.ActionTemplates.all();
            const newName = getNewTemplateName(existingTemplates, action);
            const newTemplate = JSON.parse(JSON.stringify(action));
            newTemplate.Name = newName;
            newTemplate.Id = generateGuid();
            newTemplate.Version = 1;
            newTemplate.Description = "Created from step '" + this.props.action.Name + "' in project '" + this.props.projectContext.state.model.Name + "'";
            return newTemplate;
        };

        await this.props.doBusyTask(async () => {
            const newTemplate = await createStepTemplateFromAction(this.props.action);
            const result = await repository.ActionTemplates.create(newTemplate);
            if (result) {
                this.setActionProperties({ ["Octopus.Action.Template.Id"]: result.Id, "Octopus.Action.Template.Version": result.Version.toString() });
                this.refreshStepLookupsImmediately();
            }
        });
    };

    private canRunInContainer() {
        return this.props.stepOther.plugin.canRunInContainer === false ? this.props.stepOther.plugin.canRunInContainer : true;
    }

    private channelsSummary() {
        const action = this.props.action;
        return action && action.Channels.length > 0 ? Summary.summary(<span>This step will only run for releases in {action.Channels.map(ch => this.getChipForChannel(ch))}</span>) : Summary.default("This step will run for releases in any channel");
    }

    private getChipForChannel(id: string) {
        const channel = this.props.stepLookups.channels.find(c => c.Id === id);
        return channel ? <ChannelChip key={channel.Id} channelName={channel.Name} /> : <MissingChip lookupId={id} type={ChipIcon.Channel} />;
    }

    private setActionProperties = (properties: Partial<ActionProperties>, callback?: () => void) => {
        const action = this.props.action;
        this.props.processContext.actions.setActionProperties(action.Id, properties);
        if (callback) {
            callback();
        }
    };

    private setActionPackages = (packages: PackageReference[]) => {
        this.setActionMetaProperties({ Packages: packages });
    };

    private setStepProperties = (properties: Partial<ActionProperties>) => {
        const step = this.props.step;
        this.props.processContext.actions.setStepProperties(step.Id, properties);
    };

    private setActionMetaProperties<K extends keyof StoredAction>(state: Pick<StoredAction, K> | StoredAction, callback?: () => void) {
        const action = this.props.action;
        this.props.processContext.actions.setActionMetaProperties(action.Id, prev => ({ ...prev, ...state }));
        if (callback) {
            callback();
        }
    }

    private setStepMetaProperties<K extends keyof StoredStep>(state: Pick<StoredStep, K>, callback?: () => void) {
        const step = this.props.step;
        this.props.processContext.actions.setStepMetaProperties(step.Id, prev => ({ ...prev, ...state }));
        if (callback) {
            callback();
        }
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isBuiltInWorkerEnabled: state.configurationArea.features.isBuiltInWorkerEnabled,
    };
};

const ProcessActionDetailsWithContext = withOptionalRunbookContext(withProjectContext(withProcessContext(withProcessErrorSelectorsContext(withProcessQueryStringContext(ProcessActionDetailsInternal)))));

const ProcessActionDetails = connect<void, {}, ProcessActionDetailsProps>(mapGlobalStateToProps)(ProcessActionDetailsWithContext);

export default ProcessActionDetails;

import * as React from "react";
import FormPaperLayout, { FormPaperLayoutProps } from "components/FormPaperLayout";
import { RouteComponentProps, withRouter } from "react-router";
import { useProcessContext } from "../Contexts/ProcessContext";
import routeLinks from "routeLinks";
import { ContextAddStepButton } from "../../Steps";
import { useRunbookContext, useOptionalRunbookContext } from "../../Runbooks/RunbookContext";
import { FormTitleForRunbook } from "../../Runbooks/LastPublishedChip";
import ContextualRunNowButton from "../../Runbooks/RunNowButton";
import RunbooksFormPaperLayout from "../../Runbooks/Layouts/RunbooksFormPaperLayout";
import StringHelper from "utils/StringHelper";
import { ProcessType } from "client/resources";

type ProcessContextFormPaperLayoutPropsInternal = {};

type ProcessContextFormPaperLayoutProps = FormPaperLayoutProps & ProcessContextFormPaperLayoutPropsInternal & RouteComponentProps<{ projectSlug: string }>;
const ProcessContextFormPaperLayout: React.FC<ProcessContextFormPaperLayoutProps> = ({ children, ...rest }) => {
    const processContext = useProcessContext();
    const type = processContext.selectors.getProcessType();
    const hasSteps = processContext.selectors.hasSteps();
    const projectSlug = rest.match.params.projectSlug;

    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;

    if (type === ProcessType.Runbook) {
        return (
            <RunbooksProcessEditorFormPaperLayout title={runbookName} breadcrumbTitle="Runbooks" hasSteps={hasSteps} projectSlug={projectSlug} disableAnimations={true} {...rest}>
                {children}
            </RunbooksProcessEditorFormPaperLayout>
        );
    } else {
        return (
            <DeploymentsProcessEditorFormPaperLayout title={"Process Editor"} breadcrumbTitle="Process" projectSlug={projectSlug} disableAnimations={true} {...rest}>
                {children}
            </DeploymentsProcessEditorFormPaperLayout>
        );
    }
};

export const EnhancedProcessContextFormPaperLayout = withRouter(ProcessContextFormPaperLayout);

const DeploymentsProcessEditorFormPaperLayout: React.FC<FormPaperLayoutProps & { projectSlug: string }> = ({ projectSlug, ...props }) => {
    const projectLinks = routeLinks.project(projectSlug);
    const { title = "Process Editor", breadcrumbPath = projectLinks.deployments.process.root, breadcrumbTitle = "Process", fullWidth = true, ...rest } = props;
    return (
        <FormPaperLayout title={title} secondaryAction={<ContextAddStepButton />} breadcrumbPath={breadcrumbPath} breadcrumbTitle={breadcrumbTitle} fullWidth={fullWidth} {...rest}>
            {props.children}
        </FormPaperLayout>
    );
};

const RunbooksProcessEditorFormPaperLayout: React.FC<ProcessContextFormPaperLayoutProps & { projectSlug: string; hasSteps: boolean }> = ({ projectSlug, hasSteps, ...props }) => {
    const runbooksContext = useRunbookContext();
    const title = <FormTitleForRunbook runbookName={runbooksContext.state.runbook?.Name ?? ""} />;

    // Our secondaryAction here cannot be an ActionList, as these actions end up in an ActionList via the PaperLayout anyway, and if you
    // nest an ActionList in another ActionList, you get weird margin issues.
    const secondaryAction = (
        <>
            <ContextualRunNowButton isDisabled={!hasSteps} />
            <ContextAddStepButton />
        </>
    );
    return (
        <RunbooksFormPaperLayout title={title} breadcrumbTitle={"Runbooks"} breadcrumbPath={routeLinks.project(projectSlug).operations.runbooks} secondaryAction={secondaryAction} {...props}>
            {props.children}
        </RunbooksFormPaperLayout>
    );
};
